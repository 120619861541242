@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
}

.hero-image, 
.project-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 60vh;
}
.cert-image {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;

}
